<template>
  <div class="dashboard mb-5">
    <div class="row mb-4">
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                  Total Groups
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h5 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.groups.total }}
                </h5>
                <router-link to="/groups" class="small text-decoration-underline"
                  >View groups</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-danger rounded fs-3">
                  <i class="ri-sort-desc text-danger"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
       <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p  class="text-uppercase fw-medium text-muted text-truncate mb-0" >
                 All Users
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4" >
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.users.total}}
                </h4>
                <router-link to="/users" class="small text-decoration-underline"
                  >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded fs-3">
                  <i class="ri ri-team-line text-info"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                  All Group heads
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4" >
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.users.head}}
                </h4>
                <router-link to="/users?role=head" class="small text-decoration-underline" >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-primary rounded fs-3">
                  <i class="ri-team-line text-primary"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0" >
                  All Members
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.users.member}}
                </h4>
                <router-link to="/users?role=user" class="text-decoration-underline" >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-warning rounded fs-3">
                  <i class="ri-team-line text-warning"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <statistic-apex-count
          title="Pending Groups"
          :count-end="result.groups.pending"
          :total="result.groups.total"
          :figure="result.groups.pending"
        />
      </div>
      <div class="col-md-6">
        <statistic-apex-count
          title="Approved Groups"
          :count-end="result.groups.approved"
          :total="result.groups.total"
          :figure="result.groups.approved"
        />
      </div>
      <div class="col-md-6">
        <statistic-apex-count
          title="Verified Users"
          :count-end="result.users.user_verified"
          :total="result.users.total"
          :figure="result.users.user_verified"
          />
      </div>
      <div class="col-md-6">
        <statistic-apex-count
          title="Unverified Users"
          :count-end="result.users.user_unverified"
          :total="result.users.total"
          :figure="result.users.user_unverified"
          />
      </div>
    </div>
  </div>
</template>

<script>

import StatisticApexCount from '@/components/widgets/StatisticApexCount.vue';

export default {
    name: 'dashboard',
    components:{
      StatisticApexCount,
    },
    data(){
        return{
          result: {
            "groups": {
              "total": 0,
              "pending": 0,
              "approved": 0,
            },
            "users": {
               "total": 0,
               "user": 0,
               "user_verified": 0,
               "user_unverified": 0,
               "admin": 0
            },
          },
        }
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser
      },
    },
    created(){
      this.$http.get('/analytics')
      .then((response) => {
        if(response.data.success){
          this.result = response.data.data;
        }
      })
    }
}
</script>

